@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    overflow-x: hidden;
}

body>#root>div {
    height: 100vh;
}

